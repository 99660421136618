export default {
  layouts: {
    dashboard: {
      profile: 'Profil',
      logout: 'Wyloguj',
      seeDetails: 'Zobacz profil',
      accountExpired: 'Twoje konto wygasło',
      accountExpiresIn_0: 'Za {{count}} dzień wygaśnie abonament dla Twojego konta',
      accountExpiresIn_1: 'Za {{count}} dni wygaśnie abonament dla Twojego konta',
      accountExpiresIn_2: 'Za {{count}} dni wygaśnie abonament dla Twojego konta',
    },
  },

  articles: {
    details: {
      published_at: {
        label: 'Data opublikowania',
        not_published: 'Nieopublikowano',
      },
      created_at: {
        label: 'Utworzony',
        not_created: 'Nieutworzony',
      },
      additional_options: {
        label: 'Dodatkowe opcje',
        is_visible_on_main_page: 'Widoczny na stronie głównej',
      },
    },
    list: {
      item: {
        meta: {
          date: {
            not_published: 'Nieopublikowano',
          },
        },
      },
    },
    form: {
      title: 'Tytuł',
    },
  },
  kinds: {},
  ageRanges: {
    nursery: 'Żłobek',
    kindergarten: 'Przedszkole',
    primarySchool: 'Szkoła podstawowa',
    highSchool: 'Szkoła średnia',
    adults: 'Dorośli',
  },
  allergens: {
    wheat: 'Pszenica',
    rye: 'Żyto',
    barley: 'Jęczmień',
    oat: 'Owies',
    spelled: 'Orkisz',
    shellfish: 'Skorupiaki',
    eggs: 'Jaja',
    fishes: 'Ryby',
    soy: 'Soja',
    milk: 'Mleko',
    lactose: 'Laktoza',
    nuts: 'Orzechy',
    celery: 'Seler',
    mustard: 'Gorczyca',
    sesame: 'Sezam',
    sulphites: 'Dwutlenek siarki / Siarczyny',
    lupine: 'Łubin',
    mollucs: 'Mięczaki',
  },
  modal: {
    confirmDelete: {
      title: {
        institutions: 'Czy na pewno chcesz usunąć wybraną placówkę?',
        dishes: 'Czy na pewno chcesz usunąć wybraną potrawę?',
        products: 'Czy na pewno chcesz usunąć wybrany produkt?',
        menus: 'Czy na pewno chcesz usunąć wybrany jadłospis?',
        customers: 'Czy na pewno chcesz usunąć wybranego klienta?',
        accounts: 'Czy na pewno chcesz usunąć wybrane konto użytkownika?',
        articles: 'Czy na pewno chcesz usunąć wybrany artykuł?',
        subAccountRemove: 'Czy na pewno chcesz usunąć wybrane subkonto?',
        emailTemplates: 'Czy na pewno chcesz usunąć wybrane wiadomość?',
        student: 'Czy na pewno chcesz usunąć wybranego studenta?',
        universityGroup: 'Czy na pewno chcesz usunąć wybraną grupe?',
        prints: 'Czy na pewno chcesz usunąć wybrany wydruk?',
      },
      type: {
        institutions: 'Placówka',
        dishes: 'Potrawa',
        products: 'Produkt',
        menus: 'Jadłospis',
        customers: 'Klient',
        accounts: 'Konto',
        articles: 'Artykuł',
        subAccountRemove: 'Sub konto',
        emailTemplates: 'Wiadomość',
        student: 'Student',
        universityGroup: 'Grupa',
        prints: 'Wydruk',
      },
      message: {
        institutions: {
          success: 'Placówka została usunięta',
          error: 'Nie udało się usunąć placówki',
        },
        dishes: {
          success: 'Potrawa została usunięta',
          error: 'Nie udało się usunąć potrawy',
        },
        products: {
          success: 'Produkt został usunięty',
          error: 'Nie udało się usunąć produktu',
        },
        menus: {
          success: 'Jadłospis został usunięty',
          error: 'Nie udało się usunąć jadłospisu',
          description:
            'Jadłospis zostanie jeszcze przeniesiony do kosza na 30 dni. Po upływie tego czasu zostanie trwale usunięty.',
        },
        customers: {
          success: 'Klient został usunięty',
          error: 'Nie udało się usunąć konta klienta',
        },
        accounts: {
          success: 'Konto zostało usunięte',
          error: 'Nie udało się usunąć konta',
        },
        articles: {
          success: 'Artykuł został usunięty',
          error: 'Nie udało się usunąć artykułu',
        },
        student: {
          success: 'Student został usunięty',
          error: 'Nie udało się usunąć studenta',
        },
        universityGroup: {
          success: 'Grupa została usunięta',
          error: 'Nie udało się usunąć grupy',
        },
        prints: {
          success: 'Wydruk został usunięty',
          error: 'Nie udało się usunąć wydruku',
        },
      },
    },
    confirmShare: {
      title: 'Udostępnianie',
    },
  },
  pages: {
    dashboard: {
      articles: {
        article: 'artykuł',
        help: 'pomoc',
      },
      settings: {
        accounts: {
          item: {
            institutionsCounter_0: '{{count}} placówka',
            institutionsCounter_1: '{{count}} placówki',
            institutionsCounter_2: '{{count}} placówek',
          },
        },
        form: {
          limit_reached_1: '{{count}} subkonta',
          limit_reached_2: '{{count}} subkont',
        },
      },
    },
    institutions: {
      details: {
        attendees: {
          gender: {
            BOTH: '',
            MALE: 'Chłopcy',
            FEMALE: 'Dziewczęta',
            MALE_ADULT: 'Mężczyźni',
            FEMALE_ADULT: 'Kobiety',
          },
        },
      },
      form: {
        limit_reached_0: '{{count}} placówkę',
        limit_reached_1: '{{count}} placówki',
        limit_reached_2: '{{count}} placówek',
      },
    },
    products: {
      form: {
        allergens: {
          allergenWheat: 'Pszenica',
          allergenRye: 'Żyto',
          allergenBarley: 'Jęczmień',
          allergenOat: 'Owies',
          allergenSpelled: 'Orkisz',
          allergenShellfish: 'Skorupiaki',
          allergenEggs: 'Jaja',
          allergenFishes: 'Ryby',
          allergenSoy: 'Soja',
          allergenMilk: 'Mleko',
          allergenLactose: 'Laktoza',
          allergenNuts: 'Orzechy',
          allergenCelery: 'Seler',
          allergenMustard: 'Gorczyca',
          allergenSesame: 'Sezam',
          allergenSulphites: 'Dwutlenek siarki / Siarczyny',
          allergenLupine: 'Łubin',
          allergenMollucs: 'Mięczaki',
        },
        groups: {
          VEGETABLES_AND_PRESERVES: 'Warzywa i przetwory',
          FRUIT_AND_PRESERVES: 'Owoce i przetwory',
          GRAIN_PRODUCTS: 'Produkty zbożowe',
          MILK_AND_DAIRY_PRODUCTS: 'Mleko i produkty mleczne',
          MEAT_AND_PRESERVES: 'Mięso i przetwory',
          FISH_AND_PRESERVES: 'Ryby i przetwory',
          EGGS: 'Jaja',
          FATS: 'Tłuszcze',
          NUTS_SEEDS_AND_GRAINS: 'Orzechy, nasiona i ziarna',
          SUGAR_AND_CONFECTIONERY: 'Cukier, wyroby cukiernicze',
          DRINKS: 'Napoje',
          YEAST_AND_SPICES: 'Drożdże i przyprawy',
          DISHES: 'Potrawy',
        },
        kinds: {
          vegetarian: 'Wegetariański',
          glutenFree: 'Bezglutenowy',
          dairyFree: 'Bezmleczny',
          eggFree: 'Bezjajeczny',
        },
      },
      details: {
        kinds: {
          vegetarian: 'Wegetariański',
          glutenFree: 'Bezglutenowy',
          dairyFree: 'Bezmleczny',
          eggFree: 'Bezjajeczny',
        },
        allergens: {
          wheat: 'Pszenica',
          rye: 'Żyto',
          barley: 'Jęczmień',
          oat: 'Owies',
          spelled: 'Orkisz',
          shellfish: 'Skorupiaki',
          eggs: 'Jaja',
          fishes: 'Ryby',
          soy: 'Soja',
          milk: 'Mleko',
          lactose: 'Laktoza',
          nuts: 'Orzechy',
          celery: 'Seler',
          mustard: 'Gorczyca',
          sesame: 'Sezam',
          sulphites: 'Dwutlenek siarki / Siarczyny',
          lupine: 'Łubin',
          mollucs: 'Mięczaki',
        },
      },
    },
    dishes: {
      details: {
        categories: {
          breakfast: 'Śniadanie',
          secondBreakfast: 'Śniadanie II',
          second_breakfast: 'Śniadanie II',
          soupDinner: 'Zupa - obiad',
          soup_dinner: 'Zupa - obiad',
          secondCourseDinner: 'II danie - obiad',
          second_course_dinner: 'II danie - obiad',
          afternoonTea: 'Podwieczorek',
          afternoon_tea: 'Podwieczorek',
          supper: 'Kolacja',
          drink: 'Napój',
          dessert: 'Deser',
        },
      },
    },
    menus: {
      details: {
        institutions: {
          type: {
            NURSERY: 'Żłobek',
            KINDERGARTEN: 'Przedszkole',
            SCHOOL: 'Szkoła',
            KINDERGARTEN_AND_NURSERY_COMPLEX: 'Zespół Przedszkolno-Żłobkowy',
            INSTITUTION_WITH_ALL_DAY_NUTRITION: 'Placówka z żywieniem całodziennym',
            CARE_AND_EDUCATIONAL_COMPLEX: 'Placówka Opiekuńczo-Wychowawcza',
            SCHOOL_AND_KINDERGARTEN_COMPLEX: 'Placówka Szkolno-Przedszkolna',
          },
        },
        days: {
          amount_0: '{{count}} dzień',
          amount_1: '{{count}} dni',
          amount_2: '{{count}} dni',
        },
      },
      list: {
        institutions: {
          type: {
            NURSERY: 'Żłobek',
            KINDERGARTEN: 'Przedszkole',
            SCHOOL: 'Szkoła',
            KINDERGARTEN_AND_NURSERY_COMPLEX: 'Zespół Przedszkolno-Żłobkowy',
            INSTITUTION_WITH_ALL_DAY_NUTRITION: 'Placówka z żywieniem całodziennym',
            CARE_AND_EDUCATIONAL_COMPLEX: 'Placówka Opiekuńczo-Wychowawcza',
            SCHOOL_AND_KINDERGARTEN_COMPLEX: 'Placówka Szkolno-Przedszkolna',
          },
        },
        duration: {
          amount_0: '{{count}} dzień',
          amount_1: '{{count}} dni',
          amount_2: '{{count}} dni',
        },
      },
    },
    customers: {
      list: {
        summary: {
          title: 'Podsumowanie',
          total_0: '{{count}} klient',
          total_1: '{{count}} klientów',
          total_2: '{{count}} klientów',
          active_0: '{{count}} aktywny klient',
          active_1: '{{count}} aktywnych klientów',
          active_2: '{{count}} aktywnych klientów',
          notActive_0: '{{count}} nieaktywny klient',
          notActive_1: '{{count}} nieaktywnych klientów',
          notActive_2: '{{count}} nieaktywnych klientów',
        },
      },
      details: {
        user: {
          institutions: {
            amount_0: '{{count}} placówka',
            amount_1: '{{count}} placówki',
            amount_2: '{{count}} placówek',
          },
        },
      },
    },

    emailTemplates: {
      list: {
        summary: {
          title: 'Lista wiadomości',
          total: 'Łaczna ilość szablonów: ',
        },
      },
    },
    culinary_set: {
      kinds: {
        vegetarian: 'Wegetariański',
        gluten_free: 'Bezglutenowy',
        dairy_free: 'Bezmleczny',
        egg_free: 'Bezjajeczny',
      },
      allergens: {
        wheat: 'Pszenica',
        rye: 'Żyto',
        barley: 'Jęczmień',
        oat: 'Owies',
        spelled: 'Orkisz',
        shellfish: 'Skorupiaki',
        eggs: 'Jaja',
        fishes: 'Ryby',
        soy: 'Soja',
        milk: 'Mleko',
        lactose: 'Laktoza',
        nuts: 'Orzechy',
        celery: 'Seler',
        mustard: 'Gorczyca',
        sesame: 'Sezam',
        sulphites: 'Dwutlenek siarki / Siarczyny',
        lupine: 'Łubin',
        mollucs: 'Mięczaki',
      },
    },
  },
  components: {
    shared: {
      list: {
        summary: {
          title: 'Podsumowanie',
          articles: {
            amount_0: '{{count}} artykuł',
            amount_1: '{{count}} artykuły',
            amount_2: '{{count}} artykułów',
          },
          institutions: {
            amount_0: '{{count}} placówka',
            amount_1: '{{count}} placówki',
            amount_2: '{{count}} placówek',
          },
          customers: {
            amount_0: '{{count}} klient',
            amount_1: '{{count}} klientów',
            amount_2: '{{count}} klientów',
            isActive_0: '{{count}} aktywny klient',
            isActive_1: '{{count}} aktywnych klientów',
            isActive_2: '{{count}} aktywnych klientów',
            isNotActive_0: '{{count}} nieaktywny klient',
            isNotActive_1: '{{count}} nieaktywnych klientów',
            isNotActive_2: '{{count}} nieaktywnych klientów',
          },
          products: {
            amount_0: '{{count}} produkt',
            amount_1: '{{count}} produkty',
            amount_2: '{{count}} produktów',
          },
          dishes: {
            amount_0: '{{count}} potrawa',
            amount_1: '{{count}} potrawy',
            amount_2: '{{count}} potraw',
          },
          menus: {
            amount_0: '{{count}} jadłospis',
            amount_1: '{{count}} jadłospisy',
            amount_2: '{{count}} jadłospisów',
          },
        },
      },
    },
  },
  days: {
    days_0: '{{count}} dzień',
    days_1: '{{count}} dni',
    days_2: '{{count}} dni',
  },
  time: {
    hours_0: '{{count}} godzina',
    hours_1: '{{count}} godziny',
    hours_2: '{{count}} godzin',
    minutes_0: '{{count}} minuta',
    minutes_1: '{{count}} minuty',
    minutes_2: '{{count}} minut',
  },
  meals: {
    ALL: 'Wszystkie posiłki',
    BREAKFAST: 'Śniadanie',
    SECOND_BREAKFAST: 'Drugie śniadanie',
    DINNER: 'Obiad',
    AFTERNOON_TEA: 'Podwieczorek',
    SUPPER: 'Kolacja',
    SNACK: 'Przekąska',
    MEAL: 'Posiłek',
  },
  ageRangesWithoutAge: {
    age_range_nursery: 'Żłobek',
    age_range_kindergarten: 'Przedszkole',
    age_range_primary_school: 'Szkoła podstawowa',
    age_range_high_school: 'Szkoła średnia',
    age_range_adults: 'Dorośli',
  },
  menusAmount: {
    amount_0: '{{count}} jadłospis',
    amount_1: '{{count}} jadłospisy',
    amount_2: '{{count}} jadłospisów',
  },

  menus: {
    form: {
      days_0: 'Razem {{count}} dzień',
      days_1: 'Razem {{count}} dni',
      days_2: 'Razem {{count}} dni',
    },
  },

  page: {
    list: {
      summary: 'Podsumowanie',
      customers: {
        summary_0: '{{count}} klient', // 1
        summary_1: '{{count}} klientów', // 2, 3, 4, 22, 23, 24, 32, 33, 34, 42, 43, 44, 52, 53, 54, 62, 63, 64, 72, 73, 74, 82, 83, 84, 92, 93, 94
        summary_2: '{{count}} klientów', // 0, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 25, 26, 27, 28, 29, 30, 31, 35, 36, 37, 38, 39, 40, 41, 45, 46, 47, 48, 49, 50, 51, 55, 56, 57, 58, 59, 60, 61, 65, 66, 67, 68, 69, 70, 71, 75, 76, 77, 78, 79, 80, 81, 85, 86, 87, 88, 89, 90, 91, 95, 96, 97, 98, 99, 100
      },
    },
  },
  institutions: {
    list: {
      item: {
        peopleCounter_0: '{{count}} osoba',
        peopleCounter_1: '{{count}} osoby',
        peopleCounter_2: '{{count}} osób',
      },
    },
    details: {
      gender: {
        boys: 'Chłopcy',
        girls: 'Dziewczęta',
        men: 'Mężczyźni',
        women: 'Kobiety',
      },
      ageRange: {
        HALF_TO_ONE_YEAR: '0,5 - 1 roku',
        ONE_TO_THREE_YEARS: '1 - 3 lat',
        FOUR_TO_SIX_YEARS: '4 - 6 lat',
        SEVEN_TO_NINE_YEARS: '7 - 9 lat',
        TEN_TO_TWELVE_YEARS: '10 - 12 lat',
        THIRTEEN_TO_FIFTEEN_YEARS: '13 - 15 lat',
        SIXTEEN_TO_EIGHTEEN_YEARS: '16 - 18 lat',
        NINETEEN_TO_THIRTY_YEARS: '19 - 30 lat',
      },
      people: {
        sum_0: '{{count}} osoba',
        sum_1: '{{count}} osoby',
        sum_2: '{{count}} osób',
      },
      meals: {
        types: {
          BREAKFAST: 'Śniadanie',
          SECOND_BREAKFAST: 'Drugie śniadanie',
          DINNER: 'Obiad',
          AFTERNOON_TEA: 'Podwieczorek',
          SUPPER: 'Kolacja',
          SNACK: 'Przekąska',
          MEAL: 'Posiłek',
        },
      },
    },
    types: {
      NURSERY: 'Żłobek',
      KINDERGARTEN: 'Przedszkole',
      SCHOOL: 'Szkoła',
      KINDERGARTEN_AND_NURSERY_COMPLEX: 'Zespół Przedszkolno-Żłobkowy',
      INSTITUTION_WITH_ALL_DAY_NUTRITION: 'Placówka z żywieniem całodziennym',
      CARE_AND_EDUCATIONAL_COMPLEX: 'Placówka Opiekuńczo-Wychowawcza',
      SCHOOL_AND_KINDERGARTEN_COMPLEX: 'Placówka Szkolno-Przedszkolna',
    },
  },
  dishes: {
    dishGroups: {
      MEAT: 'Dania mięsne',
      FISH: 'Dania rybne',
      FLOUR: 'Dania mączne',
      RICE: 'Ryż',
      PASTA: 'Makarony',
      GROATS: 'Kasze',
      FROZEN: 'Mrożonki',
      SOUPS: 'Zupy',
      SAUCES: 'Sosy',
      DECOCTIONS: 'Wywary',
      VEGETABLE_AND_FRUIT_DISHES: 'Potrawy warzywne i owocowe',
      BREAD: 'Pieczywo',
      CONFECTIONERY_BREAD: 'Wyroby cukiernicze',
      COLD_COMBINATIONS: 'Dania na zimno',
      HOT_COMBINATIONS: 'Dania na ciepło',
      DRINKS: 'Napoje',
      MEATLESS: 'Dania bezmięsne',
    },
    mealCategories: {
      meal_category_breakfast: 'Śniadanie',
      meal_category_second_breakfast: 'Śniadanie II',
      meal_category_soup_dinner: 'Zupa - obiad',
      meal_category_second_course_dinner: 'II danie - obiad',
      meal_category_afternoon_tea: 'Podwieczorek',
      meal_category_supper: 'Kolacja',
      meal_category_drink: 'Napój',
      meal_category_dessert: 'Deser',
      meal_category_dinner: 'Obiad',
      meal_category_snack: 'Przekąska',
      meal_category_meal: 'posiłek',
    },
    ageRanges: {
      nursery: 'Żłobkowy (0 - 3)',
      kindergarten: 'Przedszkolny (3 - 6)',
      primary_school: 'Szkoła podstawowa (6 - 15)',
      primarySchool: 'Szkoła podstawowa (6 - 15)',
      high_school: 'Szkoła średnia (15 - 19)',
      highSchool: 'Szkoła średnia (15 - 19)',
      adults: 'Dorośli (19+)',
    },
    plannedSizeOfServing: '(wielkość porcji)',
  },
  products: {
    allergens: {
      allergen_wheat: 'pszenica',
      allergen_rye: 'żyto',
      allergen_barley: 'jęczmień',
      allergen_oat: 'owies',
      allergen_spelled: 'orkisz',
      allergen_shellfish: 'skorupiaki',
      allergen_eggs: 'jaja',
      allergen_fishes: 'ryby',
      allergen_soy: 'soja',
      allergen_milk: 'mleko',
      allergen_lactose: 'laktoza',
      allergen_nuts: 'orzechy',
      allergen_celery: 'seler',
      allergen_mustard: 'gorczyca',
      allergen_sesame: 'sezam',
      allergen_sulphites: 'siarczyny',
      allergen_lupine: 'łubin',
      allergen_mollucs: 'mięczaki',
    },
    groups: {
      VEGETABLES_AND_PRESERVES: 'warzywa i przetwory',
      FRUIT_AND_PRESERVES: 'owoce i przetwory',
      GRAIN_PRODUCTS: 'produkty zbożowe',
      MILK_AND_DAIRY_PRODUCTS: 'mleko i produkty mleczne',
      MEAT_AND_PRESERVES: 'mięso i przetwory',
      FISH_AND_PRESERVES: 'ryby i przetwory',
      EGGS: 'jaja',
      FATS: 'tłuszcze',
      NUTS_SEEDS_AND_GRAINS: 'orzechy, nasiona i ziarna',
      SUGAR_AND_CONFECTIONERY: 'cukier, wyroby cukiernicze',
      DRINKS: 'napoje',
      YEAST_AND_SPICES: 'drożdże i przyprawy',
      DISHES: 'potrawy',
    },
    kinds: {
      kind_VEGETARIAN: 'Wegetariański',
      kind_GLUTEN_FREE: 'Bezglutenowy',
      kind_DAIRY_FREE: 'Bezmleczny',
      kind_EGG_FREE: 'Bezjajeczny',
      kind_vegetarian: 'Wegetariański',
      kind_gluten_free: 'Bezglutenowy',
      kind_dairy_free: 'Bezmleczny',
      kind_egg_free: 'Bezjajeczny',
    },
    dishGroups: {
      MEAT: 'Dania mięsne',
      FISH: 'Dania rybne',
      FLOUR: 'Dania mączne',
      RICE: 'Ryż',
      PASTA: 'Makarony',
      GROATS: 'Kasze',
      FROZEN: 'Mrożonki',
      SOUPS: 'Zupy',
      SAUCES: 'Sosy',
      DECOCTIONS: 'Wywary',
      VEGETABLE_AND_FRUIT_DISHES: 'Potrawy warzywne i owocowe',
      BREAD: 'Pieczywo',
      CONFECTIONERY_BREAD: 'Wyroby cukiernicze',
      COLD_COMBINATIONS: 'Dania na zimno',
      HOT_COMBINATIONS: 'Dania na ciepło',
      DRINKS: 'Napoje',
    },
    mealCategories: {
      meal_category_breakfast: 'Śniadanie',
      meal_category_second_breakfast: 'Śniadanie II',
      meal_category_soup_dinner: 'Zupa - obiad',
      meal_category_second_course_dinner: 'II danie - obiad',
      meal_category_afternoon_tea: 'Podwieczorek',
      meal_category_supper: 'Kolacja',
      meal_category_drink: 'Napój',
      meal_category_dessert: 'Deser',
    },
    ageRanges: {
      NURSERY: 'Żłobkowy (0 - 3)',
      KINDERGARTEN: 'Przedszkolny (3 - 6)',
      PRIMARY_SCHOOL: 'Szkoła podstawowa (6 - 15)',
      HIGH_SCHOOL: 'Szkoła średnia (15 - 19)',
      ADULTS: 'Dorośli (19+)',
      age_range_nursery: 'Żłobkowy (0 - 3)',
      age_range_kindergarten: 'Przedszkolny (3 - 6)',
      age_range_primary_school: 'Szkoła podstawowa (6 - 15)',
      age_range_high_school: 'Szkoła średnia (15 - 19)',
      age_range_adults: 'Dorośli (19+)',
    },
    details: {
      mealCategories: {
        BREAKFAST: 'Śniadanie',
        SECOND_BREAKFAST: 'Drugie śniadanie',
        SOUP_DINNER: 'Zupa - obiad',
        SECOND_COURSE_DINNER: 'II danie - obiad',
        AFTERNOON_TEA: 'Podwieczorek',
        SUPPER: 'Kolacja',
        DRINK: 'Napój',
        DESSERT: 'Deser',
      },
      kinds: {
        VEGETARIAN: 'wegetariański',
        GLUTEN_FREE: 'bezglutenowy',
        DAIRY_FREE: 'bezmleczny',
        EGG_FREE: 'bezjajeczny',
      },
      allergens: {
        wheat: 'pszenica',
        rye: 'żyto',
        barley: 'jęczmień',
        oat: 'owies',
        spelled: 'orkisz',
        shellfish: 'skorupiaki',
        eggs: 'jaja',
        fishes: 'ryby',
        soy: 'soja',
        milk: 'mleko',
        lactose: 'laktoza',
        nuts: 'orzechy',
        celery: 'seler',
        mustard: 'gorczyca',
        sesame: 'sezam',
        sulphites: 'siarczyny',
        lupine: 'łubin',
        mollucs: 'mięczaki',
      },
    },
  },
  status: {
    PENDING: 'Nieopłacono',
    INVOICE_DEFERRED: 'Faktura przelewowa',
    COMPLETED: 'Opłacony',
    ERROR: 'Błąd',
    ABANDONED: 'Płatność porzucona',
    EXPIRED: 'Termin płatności wygasł',
  },
  messages: {
    menu_exists_on_shop: 'Podany jadłospis jest przypisany do sklepu.',
    axios_error: 'Błąd',
  },
  buttons: {
    back: 'Wróć',
    restore: 'Przywróć',
    cancel: 'Anuluj',
    next: 'Dalej',
  },
  emailEnumTypes: {
    excel: 'Z pliku excel',
    active_customers: 'Aktywni użytkownicy',
    not_active_customers: 'Nie aktywni użytkownicy',
    all_customers: 'Wszyscy użytkownicy',
  },
  filters: {
    FoodBaseFilter: 'Baza',
    RestrictionProductKindFilter: 'Rodzaj posiłku',
    AgeRangeFilter: 'Typ placówki',
  },
  alternatives: {
    dairyFree: 'Dieta bezmleczna',
    eggFree: 'Dieta bezjajeczna',
    glutenFree: 'Dieta bezglutenowa',
    vegetarian: 'Dieta wegetariańska',
    elimination: 'Dieta eliminacyjna',
    dairy_free: 'Dieta bezmleczna',
    dairyfree: 'Dieta bezmleczna',
    egg_free: 'Dieta bezjajeczna',
    eggfree: 'Dieta bezjajeczna',
    gluten_free: 'Dieta bezglutenowa',
    glutenfree: 'Dieta bezglutenowa',
  },
};
