export const tutorialMiddleware = (store) => (next) => (action) => {
  const result = next(action);

  if (action.type.startsWith('tutorial/')) {
    const tutorialState = store.getState().tutorial;
    localStorage.setItem('tutorialState', JSON.stringify(tutorialState));
  }

  return result;
};
