import { createSlice, PayloadAction } from '@reduxjs/toolkit';
interface TutorialState {
  currentStepId: string | null;
  isFirstTimeUser: boolean;
  isTutorialActive: boolean;
  routeUrlMappings: RouteUrlMapping;
  hasCreatedTutorialMenu: boolean;
  isTutorialEnabled: boolean;
}

export interface RouteUrlMapping {
  [key: string]: {
    route: string;
    url: string;
  };
}

const storedState = localStorage.getItem('tutorialState');
const initialState: TutorialState = storedState
  ? JSON.parse(storedState)
  : {
      currentStepId: null,
      isFirstTimeUser: true,
      isTutorialActive: false,
      routeUrlMappings: {},
      hasCreatedTutorialMenu: false,
      isTutorialEnabled: false,
    };

export const tutorialSlice = createSlice({
  name: 'tutorial',
  initialState,
  reducers: {
    setCurrentStepId: (state, action: PayloadAction<string | null>) => {
      state.currentStepId = action.payload;
    },
    setIsFirstTimeUser: (state, action: PayloadAction<boolean>) => {
      state.isFirstTimeUser = action.payload;
    },
    setTutorialActive: (state, action: PayloadAction<boolean>) => {
      state.isTutorialActive = action.payload;
    },
    setRouteUrlMapping: (state, action: PayloadAction<{ key: string; route: string; url: string }>) => {
      if (!state.routeUrlMappings) {
        state.routeUrlMappings = {};
      }
      state.routeUrlMappings[action.payload.key] = {
        route: action.payload.route,
        url: action.payload.url,
      };
    },
    setTutorialMenuCreated: (state) => {
      state.hasCreatedTutorialMenu = true;
    },
    setTutorialStatus: (state, action: PayloadAction<boolean>) => {
      state.isTutorialEnabled = action.payload;
    },
  },
});

export const {
  setCurrentStepId,
  setIsFirstTimeUser,
  setTutorialActive,
  setRouteUrlMapping,
  setTutorialMenuCreated,
  setTutorialStatus,
} = tutorialSlice.actions;

export default tutorialSlice.reducer;
